// "use client";

// import React, { useEffect, useRef, useState } from "react";
// import Image from "next/image";
// import { Link } from "@/navigation";
// import bgHeroPic from "@/../public/bghero.png";
// import Spline from "@splinetool/react-spline";
// import { Application } from "@splinetool/runtime";
// import { CtaButton } from "@/components/ui/cta-button";
// import { useTranslations } from "next-intl";

// export default function Hero() {
//   const [isSmallScreen, setIsSmallScreen] = useState(false);
//   const [isSplineLoaded, setIsSplineLoaded] = useState(false);
//   const spline = useRef<Application | null>(null);
//   const t = useTranslations("home.hero");

//   function onLoad(splineApp: Application) {
//     // save the app in a ref for later use
//     setIsSplineLoaded(true);

//     spline.current = splineApp;
//   }

//   useEffect(() => {
//     const handleResize = () => {
//       setIsSmallScreen(window.innerWidth < 768); // Adjust the size threshold as needed
//     };

//     // Initial check
//     handleResize();

//     // Add event listener for window resize
//     window.addEventListener("resize", handleResize);

//     // Clean up the event listener on component unmount
//     return () => {
//       window.removeEventListener("resize", handleResize);
//     };
//   }, []);

//   return (
//     <div className="relative z-20 pt-[10px] md:h-screen md:max-h-[780px] md:pt-0 bg-red">
//       <Image
//         alt="Light ray background"
//         width={1000}
//         height={1000}
//         placeholder="blur"
//         quality={75}
//         priority
//         className="pointer-events-none absolute inset-x-0 -top-64 z-10 mx-auto w-full h-auto select-none xs:-top-20 md:z-0 md:block"
//         src={bgHeroPic}
//       />
//       <div className="size-full overflow-hidden">
//         <section
//           className="mx-auto max-w-5xl 
//       px-6 pb-8
//       md:h-screen md:max-h-[950px] md:max-w-7xl"
//         >
//           <div className="flex h-full flex-col items-center justify-between md:flex-row md:pb-24 ">
//             <div className="origin-center-left z-10 order-2 max-w-3xl sm:shrink-0 md:order-1 lg:pl-16">
//               <div className="flex items-center justify-center md:inline-flex">
//                 <Link
//                   className="rainbowBorder group mb-10 inline-flex h-8 origin-bottom-left items-center justify-center rounded-full bg-gradient-to-r from-[#F75EE1] via-[#00EEF6] to-[#7668F6] text-[14px] leading-none text-slate-700 transition duration-200 ease-in-out hover:text-slate-950"
//                   href="#solution"
//                 >
//                   <span className="m-[1px] inline-flex h-[calc(32px-2px)] w-[calc(100%-2px)] items-center gap-1 whitespace-nowrap rounded-full bg-background px-3 py-1 leading-none ">
//                     {t("introducingTitle")}
//                     <svg
//                       className="transition-transform duration-150 ease-in-out group-hover:translate-x-0.5"
//                       fill="none"
//                       height="16"
//                       viewBox="0 0 24 24"
//                       width="16"
//                       xmlns="http://www.w3.org/2000/svg"
//                     >
//                       <path
//                         d="M10.75 8.75L14.25 12L10.75 15.25"
//                         stroke="currentColor"
//                         strokeLinecap="round"
//                         strokeLinejoin="round"
//                         strokeWidth="1.5"
//                       ></path>
//                     </svg>
//                   </span>
//                 </Link>
//               </div>
//               <h1
//                 className={`font-book font-styling font-display font-effect-hero font-gradient  font-base
//                     bg-gradient-to-br from-black to-gray-500 bg-clip-text text-center text-4xl 
//                     uppercase leading-[1.2] tracking-[.2em] text-transparent md:text-left md:text-5xl md:leading-[1.2] 
//                     whitespace-pre-line
//                     `}
//               >
//                 {t("title")}
//               </h1>
//               <p className="sans text-slate-11 mb-8 mt-4 max-w-[40rem] text-center text-base font-normal leading-7 md:text-left md:text-[1.125rem] md:leading-[1.5]">
//                 {t("description")}
//               </p>
//               <div className="flex flex-col justify-center gap-4 md:flex-row md:justify-start">
//                 <CtaButton
//                   variant="primary"
//                   isLink
//                   href="https://chromewebstore.google.com/detail/theos-ai-linkedin-sales-c/gkgjifhmaalomlfljikkncdkmhljajdg"
//                 >
//                   {t("primaryButton")}
//                 </CtaButton>
//                 <CtaButton
//                   variant="outline"
//                   isLink
//                   href="https://calendly.com/theoscopilot/15min"
//                 >
//                   {t("secondaryButton")}
//                 </CtaButton>
//               </div>
//             </div>
//             <div className="relative order-1 min-h-[154px] min-w-[225px] overflow-hidden md:hidden md:overflow-auto">
//               <video
//                 autoPlay
//                 loop
//                 playsInline
//                 className="absolute left-0 top-0 h-[154px] w-[225px]"
//                 height="156"
//                 // poster="/cube-fallback.jpg"
//                 src="/rolling-cropped.mp4"
//                 width="254"
//               ></video>
//             </div>

//             <div
//               className="md: relative 
//                       order-1 
//                       hidden 
//                       rotate-90 
//                       transform-gpu items-center 
//                       justify-center 
//                       md:order-2
//                       md:flex md:w-[700px]
//                       md:translate-x-[-15%]
//                       lg:-ml-12
//                       lg:translate-x-0
//                       lg:rotate-0 
//                       lg:animate-[open-scale-up-fade_1.5s_ease-in-out]
//                       lg:pl-12
            
//                       "
//             >
//               <div
//                 className={`relative ${
//                   isSplineLoaded && "animate-webgl-scale-in-fade"
//                 }`}
//               >
//                 <div
//                   className="size-[1024px] items-center justify-center"
//                   // style={{ width: "100%", height: "100%" }}
//                 >
//                   {!isSmallScreen && (
//                     <Spline
//                       onLoad={onLoad}
//                       className="scale-[1]"
//                       scene="https://prod.spline.design/67g8WTNhISJt0Ak0/scene.splinecode"
//                     />
//                   )}
//                 </div>
//               </div>
//             </div>
//           </div>
//         </section>
//       </div>
//     </div>
//   );
// }

"use client";

import React, { useEffect, useState } from "react";
import Image from "next/image";
import { Link } from "@/navigation";
import bgHeroPic from "@/../public/bghero.png";
import dynamic from 'next/dynamic';
import { CtaButton } from "@/components/ui/cta-button";
import { useTranslations } from "next-intl";
//import Spline from "@splinetool/react-spline";


// const Spline = dynamic(() => import("@splinetool/react-spline"), {
//   loading: () => <></>,
// });

const Spline = React.lazy(() => import("@splinetool/react-spline"));

export default function Hero() {
  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isSplineLoaded, setIsSplineLoaded] = useState(false);
  const t = useTranslations("home.hero");

  useEffect(() => {
    const handleResize = () => setIsSmallScreen(window.innerWidth < 768);
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const onLoad = () => setIsSplineLoaded(true);

  return (
    <div className="relative z-20 pt-[10px] md:h-screen md:max-h-[780px] md:pt-0 bg-red">
      <Image
        alt="Light ray background"
        src={bgHeroPic}
        placeholder="blur"
        quality={75}
        priority
        className="pointer-events-none absolute inset-x-0 -top-64 z-10 mx-auto w-full h-auto select-none xs:-top-20 md:z-0 md:block"
      />
      <div className="size-full overflow-hidden">
        <section className="mx-auto max-w-5xl px-6 pb-8 md:h-screen md:max-h-[950px] md:max-w-7xl">
          <div className="flex h-full flex-col items-center justify-between md:flex-row md:pb-24">
            <div className="origin-center-left z-10 order-2 max-w-3xl sm:shrink-0 md:order-1 lg:pl-16">
              <IntroducingLink href="#solution" text={t("introducingTitle")} />
              <h1 className="font-book font-styling font-display font-effect-hero font-gradient font-base bg-gradient-to-br from-black to-gray-500 bg-clip-text text-center text-[1.75rem] px-4 uppercase leading-[1.2] tracking-[.2em] text-transparent w-[120%] -ml-[10%] md:px-0 md:w-full md:ml-0 md:text-left md:text-5xl md:leading-[1.2] whitespace-pre-line">
                {t("title")}
              </h1>
              <p className="sans text-slate-11 mb-8 mt-4 max-w-[40rem] text-center text-base font-normal leading-7 md:text-left md:text-[1.125rem] md:leading-[1.5]">
                {t("description")}
              </p>
              <CTAButtons t={t} />
            </div>
            <MobileVideo />
            <DesktopSpline isSmallScreen={isSmallScreen} isSplineLoaded={isSplineLoaded} onLoad={onLoad} />
          </div>
        </section>
      </div>
    </div>
  );
}

const IntroducingLink = ({ href, text }) => (
  <div className="flex items-center justify-center md:inline-flex">
    <Link
      className="rainbowBorder group mb-10 inline-flex h-8 origin-bottom-left items-center justify-center rounded-full bg-gradient-to-r from-[#F75EE1] via-[#00EEF6] to-[#7668F6] text-[14px] leading-none text-slate-700 transition duration-200 ease-in-out hover:text-slate-950"
      href={href}
    >
      <span className="m-[1px] inline-flex h-[calc(32px-2px)] w-[calc(100%-2px)] items-center gap-1 whitespace-nowrap rounded-full bg-background px-3 py-1 leading-none">
        {text}
        <svg
          className="transition-transform duration-150 ease-in-out group-hover:translate-x-0.5"
          fill="none"
          height="16"
          viewBox="0 0 24 24"
          width="16"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M10.75 8.75L14.25 12L10.75 15.25"
            stroke="currentColor"
            strokeLinecap="round"
            strokeLinejoin="round"
            strokeWidth="1.5"
          ></path>
        </svg>
      </span>
    </Link>
  </div>
);

const CTAButtons = ({ t }) => (
  <div className="flex flex-col justify-center gap-4 md:flex-row md:justify-start">
    <CtaButton
      variant="primary"
      isLink
      href="/blog/get-started"
    >
      {t("primaryButton")}
    </CtaButton>
    <CtaButton
      variant="outline"
      isLink
      href="/contact"
    >
      {t("secondaryButton")}
    </CtaButton>
  </div>
);

const MobileVideo = () => (
  <div className="relative order-1 min-h-[154px] min-w-[225px] overflow-hidden md:hidden md:overflow-auto">
    <video
      autoPlay
      loop
      playsInline
      className="absolute left-0 top-0 h-[154px] w-[225px]"
      height="156"
      src="/rolling-cropped.mp4"
      width="254"
    ></video>
  </div>
);

const DesktopSpline = ({ isSmallScreen, isSplineLoaded, onLoad }) => (
  <div className="md: relative order-1 hidden rotate-90 transform-gpu items-center justify-center md:order-2 md:flex md:w-[700px] md:translate-x-[-15%] lg:-ml-12 lg:translate-x-0 lg:rotate-0 lg:animate-[open-scale-up-fade_1.5s_ease-in-out] lg:pl-12">
    <div className={`relative ${isSplineLoaded && "animate-webgl-scale-in-fade"}`}>
      <div className="size-[1024px] items-center justify-center">
        {!isSmallScreen && (

<React.Suspense fallback={<div>Loading...</div>}>
<Spline
  onLoad={onLoad}
  className="scale-[1]"
  scene="https://prod.spline.design/67g8WTNhISJt0Ak0/scene.splinecode"
/>
</React.Suspense>
          // <Spline
          //   onLoad={onLoad}
          //   className="scale-[1]"
          //   scene="https://prod.spline.design/67g8WTNhISJt0Ak0/scene.splinecode"
          // />
        )}
      </div>
    </div>
  </div>
);